<template>
    <header class="w-full h-[70px] sticky top-0 z-50 shadow-4xl bg-white flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] h-full flex justify-center items-center w-full relative">
            <div class="flex justify-between items-center w-full h-full relative">
                <div class="text-2xl uppercase font-bold h-[70px] relative">
                    <img src="../assets/logo.jpg" alt="" class="h-full w-[70px]">
                </div>
                <nav class="md:flex hidden h-full relative items-center justify-center">
                    <ul class="h-full flex items-center justify-center">
                        <li
                        v-for="(link, index) in navLinks"
                        :key="index"
                        class="mx-[10px] text-sm cursor-pointer text-gray-800 font-bold h-full flex items-center capitalize"
                        :class="{'border-b-[5px] border-blue-950': isActiveLink(link.hash)}"
                        @click="scrollToSection(link.hash)"
                        >
                            {{ link.name }}
                        </li>
                    </ul>
                </nav>
                <div>
                    <button class="ml-[15px] p-[10px] rounded-[5px] text-sm font-bold bg-transparent text-blue-950 border border-blue-950">Se connecter</button>
                    <button class="ml-[15px] p-[10px] rounded-[5px] text-sm font-bold text-white bg-blue-950 border border-blue-950">S'inscrire</button>
                </div>
            </div>
        </div>
    </header>
    <section class="w-full h-[calc(100vh-70px)] flex items-center justify-center">
        <div class="container px-[20px] lg:max-w-[1024px] w-full flex lg:flex-row flex-col-reverse items-center justify-between relative">
            <div class="lg:w-[50%] w-full flex flex-col items-center lg:items-start">
                <h1 class="text-gray-800 text-center w-full lg:text-left text-[60px] font-bold">
                    Oops!
                </h1>
                <h2 class="text-gray-600 text-center w-full lg:text-left font-bold md:text-3xl text-2xl">
                    Le site est actuellement en maintenance
                </h2>
                <p class="text-sm font-semibold w-full text-gray-500 text-center lg:text-left my-[20px]">
                    Nous travaillons à améliorer votre expérience. Merci de revenir plus tard.🙏
                </p>
                <button class="bg-[#014AA9] p-[10px] w-full md:w-auto mt-[10px] rounded-[5px] text-white text-sm font-bold">
                    <a href="https://wa.me/+2290143742700">Nous contacter</a>
                </button>
            </div>
            <div class="lg:w-[50%] w-[250px] lg:pl-[25px] flex items-center justify-center">
                <img src="../assets/images/maintenance.jpg" alt="">
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>
  
<script setup>
import FooterComponent from "@/components/FooterComponent.vue";
import { ref } from "vue"
import { useRouter } from 'vue-router'

const router = useRouter()
const errorMessage = ref('')


const navLinks = [
  { name: "à propos", hash: "#apropos" },
  { name: "services", hash: "#services" },
  { name: "Tarif", hash: "#tarif" },
  { name: "Contact", hash: "#contact" },
];

// Fonction pour défiler vers la section correspondante
const scrollToSection = (hash) => {
  const section = document.querySelector(hash);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

// Fonction pour activer le lien correspondant à la section visible
const isActiveLink = (hash) => {
  const section = document.querySelector(hash);
  if (!section) return false;

  const top = section.getBoundingClientRect().top;
  return top >= 0 && top <= window.innerHeight / 2;
};

// Détecter le scroll pour activer dynamiquement les liens
window.addEventListener("scroll", () => {
  navLinks.forEach((link) => {
    isActiveLink(link.hash);
  });
});
</script>
  
<style>
    .maintenance {
        text-align: center;
        margin-top: 20vh;
    }
</style>  